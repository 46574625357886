/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { AVComponent } from '@/components/Videoplayer/AVDisplay';
import { useCallback, useEffect, useMemo, useState } from 'react';
import PeakDataProcessor from '@/lib/PeakDataProcessor';
import { PeakDataLoader } from '@/lib/PeakDataLoader';

export interface AudioLoader {
  onAvComponentReady(data: AVComponent): void;
  avComponentRef: AVComponent | null;
  transcript: string;
}

export function useAudioLoader(): AudioLoader {
  const [avComponentRef, setAVComponentRef] = useState<AVComponent | null>(null);
  const pdp: PeakDataProcessor = useMemo(() => new PeakDataProcessor(), []);
  const pdl: PeakDataLoader = useMemo(() => new PeakDataLoader(), []);
  const [transcript, setTranscript] = useState<string>('');

  const onAvComponentReady = (data: AVComponent): void => {
    console.log('AVComponent ready', data);
    setAVComponentRef(data);
    initWaveform().catch(console.error);
  };

  useEffect(() => {
    if (avComponentRef) {
      const urlSearchParams = new URL(avComponentRef.video.src).searchParams;
      pdl.update(
        avComponentRef.audioRef,
        pdp,
        `${process.env.REACT_APP_WORKER_URL as string}/api/convert?key=${
          urlSearchParams.get('key') as string
        }`,
      );
      fetchTranscript();
    }
  }, [avComponentRef, pdp]);

  const fetchTranscript = useCallback(() => {
    if (!avComponentRef) return;
    const urlSearchParams = new URL(avComponentRef.video.src).searchParams;
    const eventSource = new EventSource(
      `${process.env.REACT_APP_WORKER_URL as string}/api/transcribe?key=${
        urlSearchParams.get('key') as string
      }`,
    );
    eventSource.onmessage = (event) => {
      const newMessage = event.data as string;
      setTranscript((prevMessages) => prevMessages + newMessage);
    };
    eventSource.onerror = (event) => {
      eventSource.close();
    };

    eventSource.onopen = () => {
      console.log('Stream opened');
    };

    eventSource.addEventListener('end', () => {
      eventSource.close();
    });
  }, [avComponentRef]);

  const initWaveform = useCallback(async () => {
    if (!avComponentRef) return;
    pdl.update(avComponentRef.audioRef, pdp, '');
    pdl.start();
    const wavesurfer = avComponentRef.audioRef;
    console.log('Initializing Waveform with silent track');
    await pdp.updateWavesurfer(wavesurfer);

    // await loadNextChunk();
  }, [avComponentRef]);

  return { onAvComponentReady, avComponentRef, transcript };
}
