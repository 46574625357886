/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { useGetProject } from '@/adapters/projects';
import Loading from '@/components/Loading';
import AVDisplay from '@/components/Videoplayer/AVDisplay';
import { WorkbenchContext } from '@/contexts/WorkbenchContext';
import { useAudioLoader } from '@/hooks/useAudioLoader';
import { FC, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styles from './Workbench.module.scss';

const Workbench: FC = () => {
  const { projectId } = useParams<{ projectId: string }>();
  const { data: project, isLoading } = useGetProject(projectId as string);
  const [videoUrl, setVideoUrl] = useState('');

  const { onAvComponentReady, transcript } = useAudioLoader();

  const { selectStartRegion, setSelectStartRegion, setSelectEndRegion, selectEndRegion } =
    useContext(WorkbenchContext);

  const handleSetTimeRange = (value: string, type: 'start' | 'end'): void => {
    if (type === 'start') {
      setSelectStartRegion(value);
    }
    if (type === 'end') {
      setSelectEndRegion(value);
    }
  };

  const handleResetSelectedRegion = (type: string): void => {
    if (type === 'start') {
      setSelectStartRegion(null);
      setSelectEndRegion(null);
    } else if (type === 'end') {
      setSelectEndRegion(null);
    }
  };

  useEffect(() => {
    if (project?.streamPath) {
      const key = new URL(project.streamPath).pathname.substring(1);
      setVideoUrl(
        `${process.env.REACT_APP_WORKER_URL as string}/api/${projectId as string}/live?key=${key}`,
      );
    }
  }, [project]);

  return (
    <>
      {isLoading || !project ? (
        <Loading />
      ) : (
        <div className={styles.workbenchContainer}>
          <div style={{ width: '100%', height: '100%' }}>
            <AVDisplay
              videoSrc={videoUrl}
              cutUrl={project.streamPath as string}
              onReady={onAvComponentReady}
              selectStartRegion={selectStartRegion}
              selectEndRegion={selectEndRegion}
              handleSetTimeRange={handleSetTimeRange}
              handleResetSelectedRegion={handleResetSelectedRegion}
            >
              {/* <Controls onEvent={handleControlEvent} /> */}
              <textarea value={transcript} className={styles.transcriptTextArea} />
            </AVDisplay>
          </div>
        </div>
      )}
    </>
  );
};

export default Workbench;
