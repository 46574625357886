/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import HlsVideoPlayer from './HlsVideoPlayer';
import Waveform, { ExtRegion, ExtRegionParams } from './Waveform';
import WaveSurfer from 'wavesurfer.js';
import { HlsVideoElement } from 'hls-video-element';
import Hls from 'hls.js';
import {
  ChangeEvent,
  Children,
  FC,
  MutableRefObject,
  ReactElement,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import RegionsPlugin, { Region } from 'wavesurfer.js/dist/plugins/regions';
import Controls from './Controls';
import styles from './videoplayer.module.scss';
import GCMinimapPlugin from '@/lib/GCMinimapPlugin';
import { useRegions } from '@/hooks/useRegions';
import { useVideCut } from '@/adapters/users';
import { WorkbenchContext } from '@/contexts/WorkbenchContext';
import TimeInput from '../TimeInput/TimeInput';
import { useNavigate, useParams } from 'react-router-dom';
import { toHHMMSS } from '@/utils';
import PrimaryButton from '../common/PrimaryButton';
import Loading from '../Loading';

export const RegionDefaultColor = 'rgba(0, 0, 255, 0.3)';
export const RegionSelectedColor = 'rgba(255, 255, 255, 0.5)';
declare module 'react' {
  interface HTMLAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    part?: string;
  }

  interface SVGAttributes<T> extends AriaAttributes, DOMAttributes<T> {
    part?: string;
  }
}

export type Nullable<T> = T | null;

export interface AVDisplayProps {
  videoSrc: string;
  cutUrl: string;
  onReady(data: AVComponent): void;
  children?: ReactNode;
  onRegionsDeleted?(regions: ExtRegion[]): void;
  selectStartRegion: Nullable<string>;
  selectEndRegion: Nullable<string>;
  handleSetTimeRange(value: string, type: 'start' | 'end'): void;
  handleResetSelectedRegion(type: string): void;
}

export interface AVComponent {
  video: HTMLVideoElement;
  audioRef: WaveSurfer;
  onControlEvent?(data: string): void;
  scrollToRegion(id: string): void;
  onRegionsLoaded(regionParams: any[]): void;
}

export interface Fragment {
  id: number;
  workerId: string;
  graduationDate: string;
  studentId: Nullable<number>;
  graduationId: Nullable<number>;
  sequenceNumber: number;
  studentSeatNumber: Nullable<number>;
  timeStart: number;
  timeEnd: number;
  timeStartAdjusted: number;
  timeEndAdjusted: number;
  color: string;
  disabled: boolean;
  locked: boolean;
  renderStatus: string;
}

const AVDisplay: FC<AVDisplayProps> = (props) => {
  const { projectId } = useParams<{ projectId: string }>();
  const videoRef: MutableRefObject<HlsVideoElement | null> = useRef(null);
  const audioRef: MutableRefObject<WaveSurfer | null> = useRef(null);
  const [audioReady, setAudioReady] = useState(false);
  const [videoReady, setVideoReady] = useState(false);
  const [audioLoaded, setAudioLoaded] = useState(false);
  const [timestamp, setTimestamp] = useState('0:00:00.000');
  const [sample, setSample] = useState('0');
  const minZoom = 1.5;
  const samplerate = 1000;
  const kiddies: { [key: string]: ReactNode } = {};
  const [wsRegions, _setWsRegions] = useState<ExtRegion[]>([]);
  const wsRegionsRef = useRef<ExtRegion[]>(wsRegions);
  const [regionParams] = useState<ExtRegionParams[]>([]);
  const [cutLoading, setCutLoading] = useState(false);
  const navigate = useNavigate();

  const { handleRegionUpdated, selectedRegions, setSelectedRegions } = useRegions();
  const { setCurrentPosition } = useContext(WorkbenchContext);
  const { mutateAsync: videoCut } = useVideCut();
  const { handleResetSelectedRegion, handleSetTimeRange } = props;

  const setWsRegions = (data: ExtRegion[]): void => {
    _setWsRegions((): ExtRegion[] => {
      const regdata = data;
      wsRegionsRef.current = regdata;
      return regdata;
    });
  };

  Children.map(props.children, (child) => {
    (child as ReactElement).type === Controls && (kiddies['Controls'] = child);
  });

  useEffect(() => {
    const keyListener = (e: KeyboardEvent): void => {
      if (!videoRef.current) return;

      const player = videoRef.current;
      if (e.key === ' ') {
        e.preventDefault();

        if (player.paused) {
          player.play().catch(console.error);
        } else {
          player.pause();
        }
      }
    };
    window.addEventListener('keydown', keyListener);
    return () => {
      window.removeEventListener('keydown', keyListener);
    };
  }, []);

  const handleRegionsSelected = (regions: ExtRegionParams[]): void => {
    const regionIds = new Set(regions.map((region) => region.id));
    const _wsRegions = wsRegionsRef.current;
    for (const region of _wsRegions) {
      region.selected = regionIds.has(region.id);
      const colorOption = region.selected ? RegionSelectedColor : RegionDefaultColor;
      region.setOptions({ start: region.start, color: colorOption });
    }
  };

  useEffect(() => {
    if (selectedRegions.length === 0) return;
    const last = selectedRegions[selectedRegions.length - 1];
    if (!last.id) return;
    handleRegionsSelected(selectedRegions);
    handleScrollToRegion(last.id);
    videoRef.current && (videoRef.current.currentTime = last.start);
  }, [selectedRegions]);

  useEffect(() => {
    const player = videoRef.current;
    if (!player) return;

    player.src = props.videoSrc;

    if (!player.api) {
      return;
    }
    const hls: Hls = player.api;

    Object.assign(hls.config, {
      startPosition: 0,
    });

    const handleMediaAttached = (): void => hls.startLoad(0);
    const handleManifestParsed = (): void => {
      setVideoReady(true);
    };
    const handleError = (event: any, data: any): void => console.error('HLS Error', data);

    hls.on(Hls.Events.MEDIA_ATTACHED, handleMediaAttached);
    hls.on(Hls.Events.MANIFEST_PARSED, handleManifestParsed);
    hls.on(Hls.Events.ERROR, handleError);

    return () => {
      hls.off(Hls.Events.MEDIA_ATTACHED, handleMediaAttached);
      hls.off(Hls.Events.MANIFEST_PARSED, handleManifestParsed);
      hls.off(Hls.Events.ERROR, handleError);
    };
  }, [props.videoSrc]);

  useEffect(() => {
    if (!audioReady || !videoReady) {
      return;
    }
    if (videoRef.current === null || audioRef.current === null) {
      console.error('Video or Audio Ref is null');
      return;
    }
    // console.log('Audio Ready:', audioReady, 'Video Ready:', videoReady, props);

    props.onReady({
      video: videoRef.current,
      audioRef: audioRef.current,
      scrollToRegion: handleScrollToRegion,
      onRegionsLoaded: () => null,
    });
  }, [audioReady, videoReady]);

  useEffect(() => {
    if (!audioLoaded) return;

    const waveSurfer = audioRef.current;
    if (!waveSurfer) return;
    waveSurfer.on('load', () => {
      console.log('Audio Loaded');
    });
    const rp = waveSurfer.getActivePlugins()[1] as RegionsPlugin;
    const currentWsRegions = wsRegionsRef.current;
    const wsRegionsById = currentWsRegions.reduce<{ [key: string]: ExtRegion }>((acc, region) => {
      acc[region.id] = region;
      return acc;
    }, {});

    const _wsRegions = regionParams.map((regionParam: ExtRegionParams) => {
      console.log('Region Param', regionParam);
      if (regionParam.id && wsRegionsById[regionParam.id]) {
        const wsRegion = wsRegionsById[regionParam.id];
        console.log(regionParam.id, wsRegion);
        if (wsRegion.element) {
          wsRegion.setOptions(regionParam);
        }
        wsRegion.regionParams = regionParam;
        wsRegion.fragment = regionParam.fragment;
        wsRegion.selected = regionParam.selected;
        wsRegion.locked = regionParam.locked;
        delete wsRegionsById[regionParam.id];
        return wsRegion;
      } else {
        return rp.addRegion(regionParam);
      }
    }) as ExtRegion[];

    Object.keys(wsRegionsById).forEach((key) => {
      wsRegionsById[key].remove();
    });
    setWsRegions([..._wsRegions]);
  }, [regionParams, audioLoaded]);

  useEffect(() => {
    // console.log("Regions Updated");
    const player = videoRef.current;
    const waveSurfer = audioRef.current;
    if (!player) return;
    if (!waveSurfer) return;
    const rp = waveSurfer.getActivePlugins()[1] as RegionsPlugin;

    let regionLastClicked: Region | null = null;
    const rpRegionClicked = (region: ExtRegion | null, e: MouseEvent): void => {
      // console.log("Region Clicked", region, e, "SHIFT Key:", e.shiftKey);
      if (!region) return;
      e.preventDefault();
      e.stopPropagation();
      if (region.start > 0) {
        player.currentTime = region.start;
      }
      //Rearranging student list creates new set of regions
      const lastRegionValid = regionLastClicked && regionLastClicked.element;
      const _wsRegions = wsRegionsRef.current;
      // console.log("Shift-click, regions", _wsRegions);

      if (regionLastClicked && lastRegionValid) {
        _wsRegions.forEach((r) => {
          if (r.selected) {
            r.selected = false;
            r.setOptions({ start: r.start, color: RegionDefaultColor });
          }
        });
      }
      if (e.shiftKey) {
        if (regionLastClicked) {
          const _rlc = regionLastClicked;
          const selectedRegions = _wsRegions.reduce<ExtRegion[]>((acc, _current) => {
            if (_current.start >= _rlc.start && _current.start <= region.start) {
              return [...acc, _current];
            }
            return acc;
          }, []);
          console.log('Selected Regions', selectedRegions);
          selectedRegions.forEach((r: ExtRegion) => {
            console.log('Selected Region', r);
            r.selected = true;
            r.setOptions({ start: r.start, color: RegionSelectedColor });
          });
          const regionParams = selectedRegions.map((r) => r.regionParams);
          setSelectedRegions(regionParams);
        }
      } else {
        region.selected = true;
        region.setOptions({ start: region.start, color: RegionSelectedColor });
        regionLastClicked = region;
        setSelectedRegions([region.regionParams]);
      }
    };

    rp.on('region-created', (_region) => {
      const region = _region as ExtRegion;
      console.log('Region Created, setting up event listeners...', region);
      region.on('update', (position: string | undefined) => {
        console.log('Region Updated', region, position);
        if (!position) return;
        if ('start'.localeCompare(position) === 0) {
          player.currentTime = region.start;
        } else {
          player.currentTime = region.end;
        }
      });
      region.on('update-end', () => {
        handleRegionUpdated && handleRegionUpdated(region);
      });
      region._subscribe = (e: MouseEvent) => rpRegionClicked(region, e);
      region._unsubscribe = region.on('click', region._subscribe);
    });
    rp.on('region-removed', (region) => {
      const wsRegion = wsRegions.filter((r) => r.id !== region.id);
      setWsRegions(wsRegion);
    });
    return () => {
      rp.unAll();
    };
  }, [videoRef, audioRef]);

  const handleScrollToRegion = (id: string): void => {
    const waveSurfer = audioRef.current;
    if (!waveSurfer) return;
    console.log('Scrolling to region', id);
    const region = wsRegionsRef.current.find((r) => r.id === id);
    if (!region) return;
    waveSurfer.setScrollTime(region.start - 50);
  };

  const handleVideoPlayerReady = (player: HlsVideoElement): void => {
    videoRef.current = player;
  };

  const handleWaveformReady = (wavesurfer: WaveSurfer): void => {
    console.log('WaveformReady', wavesurfer);
    setAudioLoaded(true);
  };

  const handleWaveformComponentLoad = (wavesurfer: WaveSurfer): void => {
    console.log('Wavesurfer is loaded and ready', wavesurfer);
    const player = videoRef.current;
    if (!player) return;

    // console.log("Player:", player);
    audioRef.current = wavesurfer;
    // Wavesurfer has to fully render at least once before we can interact with it
    //TODO wavesurfer.load("", [peaks], duration);
    wavesurfer.on('interaction', (value) => {
      console.log('Wavesurfer Interaction', value);
      player.currentTime = value;
      setCurrentPosition(value);
    });
    const minimap = wavesurfer.getActivePlugins()[0] as unknown as GCMinimapPlugin;
    minimap.on('interaction', (value: number) => {
      console.log('Minimap Interaction', value);
      wavesurfer.setScrollTime(value);
    });

    player.addEventListener('timeupdate', () => {
      const currentTime = player.currentTime;
      wavesurfer.setTime(currentTime);
      setCurrentPosition(currentTime);
      const sample_number = Math.floor(currentTime * samplerate);
      // const sample_number_to = sample_number + 50;
      setSample(sample_number.toString());
      setTimestamp(new Date(currentTime * 1000).toISOString().substr(11, 12));
      // console.log("Samples " + sample_number + " to " + sample_number_to, peaks.slice(sample_number, sample_number_to));
    });
    console.log('SETAUDIOREADY');
    setAudioReady(true);
  };

  const maxZoom = 200;
  const maxSteps = 250;
  const logMaxZoom = Math.log(maxZoom);

  const onZoom = (event: ChangeEvent<HTMLInputElement>): void => {
    if (audioRef.current) {
      const logMinZoom = Math.log(minZoom);
      const ws = audioRef.current;
      const value = Number(event.target.value);
      const logZoom = logMinZoom + ((logMaxZoom - logMinZoom) * value) / (maxSteps - 1);
      const zoom = Math.exp(logZoom);
      ws.zoom(zoom);
    }
  };

  const handleVideoCut = async (): Promise<void> => {
    setCutLoading(true);
    const payload = {
      start: toHHMMSS(props.selectStartRegion as string),
      duration: toHHMMSS(props.selectEndRegion as string),
      sourceFilePath: props.cutUrl,
      projectId: projectId as string,
    };
    await videoCut(payload);
    setCutLoading(false);
    navigate('/projects');
  };

  return (
    <>
      <div className={styles.videoContainer}>
        <div style={{ width: '50%' }}>{props.children}</div>
        <HlsVideoPlayer onReady={handleVideoPlayerReady} />
      </div>
      <div className={styles.waveFormSlider}>
        <div className={styles.audioSlider}>
          <input
            type="range"
            min={minZoom}
            max={maxZoom}
            step={2}
            onChange={onZoom}
            style={{ width: '100%' }}
          />
        </div>
        <div className="flex-none">
          <p>Sample#: {sample}</p>
        </div>
        <div className="flex-none">
          <p>T/stamp: {timestamp}</p>
        </div>
        <div className="flex-none" onClick={(): void => handleResetSelectedRegion('start')}>
          <p>Start Selected: {props.selectStartRegion || '00:00:00'}</p>
        </div>
        <div className="flex-none" onClick={(): void => handleResetSelectedRegion('start')}>
          <p>End Selected: {props.selectEndRegion || '00:00:00'}</p>
        </div>
      </div>
      <Waveform
        onReady={handleWaveformReady}
        onComponentLoad={handleWaveformComponentLoad}
        regionsVisible={true}
      />
      <div className={styles.cutButtonContainer}>
        <div>
          <TimeInput
            placeholder="Start time"
            onChange={(value) => {
              handleSetTimeRange(value, 'start');
            }}
          />
          <TimeInput
            placeholder="End time"
            onChange={(value) => {
              handleSetTimeRange(value, 'end');
            }}
          />
        </div>
        <PrimaryButton onClick={handleVideoCut} disabled={!audioReady || cutLoading}>
          {cutLoading ? <Loading /> : 'Cut Video'}
        </PrimaryButton>
      </div>
    </>
  );
};

export default AVDisplay;
