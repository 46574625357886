import { FC, useCallback } from 'react';
import HlsVideo from 'hls-video-element/react';
import {
  MediaControlBar,
  MediaController,
  MediaLoadingIndicator,
  MediaMuteButton,
  MediaPlayButton,
  MediaTimeDisplay,
  MediaTimeRange,
  MediaVolumeRange,
} from 'media-chrome/react';
import HlsVideoElement from 'hls-video-element/*';
import styles from './videoplayer.module.scss';

interface HlsVideoPlayerProps {
  onReady(videoRef: HlsVideoElement): void;
}

const HlsVideoPlayer: FC<HlsVideoPlayerProps> = function ({ onReady }) {
  const videoRef = useCallback(
    (videoRef: HlsVideoElement) => {
      // console.log('videoRef:', videoRef);
      if (videoRef && onReady) {
        onReady(videoRef);
      }
    },
    [onReady],
  );
  return (
    <>
      <section className={styles.videoplayer}>
        <MediaController className={styles.gcMediaController}>
          <HlsVideo
            ref={videoRef}
            // poster="/preview.jpg"
            preload="auto"
            slot="media"
            playsInline
          />
          <MediaLoadingIndicator />
          <MediaControlBar>
            <MediaPlayButton></MediaPlayButton>
            <MediaTimeRange></MediaTimeRange>
            <MediaTimeDisplay showDuration></MediaTimeDisplay>
            <MediaMuteButton></MediaMuteButton>
            <MediaVolumeRange></MediaVolumeRange>
          </MediaControlBar>
        </MediaController>
      </section>
    </>
  );
};

export default HlsVideoPlayer;
